import React from "react"
import { Helmet } from "react-helmet"
import styles from './contato.module.css'

import Layout from "../components/layout"
import Container from "../components/container"

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
      />
      <meta property="og:description" content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
      />
      <meta name="image" content='/img/voga1.jpg' />
      <meta property="og:image" content='/img/voga1.jpg' />
      <title>Contato - Grupo Vinte</title>
      <link rel="canonical" href="https://grupovinte.com.br/contato/" />
      <html lang="pt-br" />
    </Helmet>
    <main style={{ minHeight: '72vh' }}>
      <Container>
        <div className={styles.content}>
          <div className={styles.text}>
            <form name="contact" method="post" action="/sucesso/" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="bot-field" />
              <div className={styles.field}>
                <label className="label">Nome</label>
                <div className="control">
                  <input name="name" id="name" className={styles.input} type="text" required="true" placeholder="Digite seu nome" />
                </div>
              </div>

              <div className={styles.field}>
                <label className="label">Email</label>
                <div className="control">
                  <input name="email" id="email" className={styles.input} type="email" required="true" placeholder="Digite seu email" />
                </div>
              </div>

              <div className={styles.field}>
                <label className="label">Mensagem</label>
                <div className="control">
                  <textarea name="message" id="message" className={styles.textarea} required="true" placeholder="Digite sua mensagem"></textarea>
                </div>
              </div>

              <div className={styles.field}>
                <div className="control" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <input type="submit" value="Enviar" className={styles.submit} />
                </div>
              </div>
            </form>
          </div>
          <div className={styles.image}>
            <h1 style={{ marginBottom: '15px' }}>Mande um Oi pra gente!</h1>
            <img src="/img/contato.jpg" alt="Contato" />
          </div>
        </div>
        <p><strong>Endereço:</strong> Lot. Belvedere, Gramado - RS, 95670-000</p>
        <p><strong>Telefone:</strong> (54) 3286-8043</p>
      </Container>
    </main>
  </Layout>
)
